
import moment from 'moment';
import loopback from '@/services/loopback';
import { Base64 } from 'js-base64';
import { IMAGES } from "@/constants/constant"

function setStorage(key, data) {
  window.localStorage.setItem(key, Base64.encode(JSON.stringify(data)));
}

function getStorage(key) {
  try {
    var v = window.localStorage.getItem(key);
    var result = JSON.parse(Base64.decode(v));
    return result;
  } catch (error) {
    return;
 }
}

function clearStorage(key) {
  window.localStorage.clear();
}
  
function dateFormatter(value, row, index) {
  return moment(value).format('YYYY-MM-DD HH:mm:ss')
}

var _dictionary_ = null;
var _sensor_type_ = null;
var _service_type_ = null;
var _thresholds_ = null;

function getSensorTypeName(type, id, hidden_unit) {
  var result = '';
  var unit;

  _sensor_type_.some(function(e) {
    unit = hidden_unit ? '' : e.unit;
    if (id) {
      if (e.id == id && e.code == type) {
          result = e.value + " " + unit;
          return true;
      }
    } else {
      if (e.code == type) {
          result = e.value + " " + unit;
          return true;
      }
    }
  })
  return result;
}

function getFontawesomeIcon(type) {
  // console.log('getFontawesomeIcon type:', type)
  switch (Number(type)) {
    case 1: return ['fa', 'battery-three-quarters']
    case 2: return ['fa', 'cloud-rain']
    case 6: return ['fa', 'thermometer-quarter']
    case 12: return ['fa', 'exchange-alt']
    case 26: return ['fa', 'adjust']
    case 27: return ['fa', 'adjust']
    default: return ['fa', 'exchange-alt']
  }
}

var toFixed = function(value, digit) {
  if (_.isUndefined(value)) {
    return value;
  }

  var digit = digit | 2;
  if (typeof value === 'number') {
    return value.toFixed(digit);
  }
  return value;
}

function filteredItem(items, type) {
  var r = items.filter(function(item) {
    if (item.type === type) {
      item.value = toFixed(item.value);
      return true;
    }
  })

  return r;
}

function getImageByLevel(type, level) {
  var imgsrc = [];
  Object.keys(IMAGES).some(function(key, i) {
    if ((type % 64) === IMAGES[key].type && level === IMAGES[key].level) {
      imgsrc = IMAGES[key];
      return;
    }
  });
  return imgsrc;
}

function getThresholdByValue(sensor, thresholds) {
  if (sensor.id != 1 || sensor.type == 8) {
    if(sensor.type == 1){
      sensor.id = 1;
    }
    else
      return [];
  }
  // console.log(sensor);
  // if(sensor.type == 1 && sensor.id == 2){
  //     sensor.id = 1;
  //     console.log("battery 2");
  //     return [];
  // }
  var r = thresholds.filter(function(item) {
    if (item.sensor_type == sensor.type && item.sensor_id === sensor.id) {
      if ((sensor.value >= Number(item.min).toFixed(2) && sensor.value <= Number(item.max).toFixed(2))) {
        return true;
      }
    }
  })
  if (_.isEmpty(r)) {
    var threshold_max = _.max(thresholds, function(t) {
      if (t.sensor_type === sensor.type && t.sensor_id === sensor.id) return t.max
      // return t.sensor_type === sensor.type && t.max;
    })
    var threshold_min = _.min(thresholds, function(t) {
      if (t.sensor_type === sensor.type && t.sensor_id === sensor.id) return t.min;
      // return t.sensor_type === sensor.type && t.min;
    })
    if (sensor.value < threshold_min.min) {
      r = threshold_min;
    } else if (sensor.value > threshold_max.max) {
      r = threshold_max;
    }
  }

  var result = _.isArray(r) ? _.first(r) : r;
  if (result) {
    var img = getImageByLevel(sensor.type, _.get(result, "level", 0));
    result["img"] = img;
  }
  if (sensor.type == 1 || sensor.type == 12) {
    return result;
  }
  return [];
}

function getWindDirectionName(value) {
  var result = "";
  switch (value) {
    case 0:
    case 360:
      result = "북";
      break;
    case 45:
      result = "북동";
      break;
    case 90:
      result = "동";
      break;
    case 135:
      result = "남동";
      break;
    case 180:
      result = "남";
      break;
    case 225:
      result = "남서";
      break;
    case 270:
      result = "서";
      break;
    case 315:
      result = "북서";
      break;
    default:
      break;
  }
  if (value >= 1 && value <= 44)
    result = "북북동";
  else if (value >= 46 && value <= 89)
    result = "동북동";
  else if (value >= 91 && value <= 134)
    result = "동남동";
  else if (value >= 136 && value <= 179)
    result = "남남동";
  else if (value >= 181 && value <= 224)
    result = "남남서";
  else if (value >= 226 && value <= 269)
    result = "서남서";
  else if (value >= 271 && value <= 314)
    result = "서북서";
  else if (value >= 316 && value <= 359)
    result = "북북서";

  return result.toString();
}

function getPMlevelName(value, id) {
  var result = "";
  var img_value = "";
  var img_string = "";

  if (id == 6) //2.5
  {
    if (value >= 0 && value <= 15) {
      result = "좋음";
      img_value = "img/humidity-normal.png";
    } else if (value >= 16 && value <= 35) {
      result = "보통";
      img_value = "img/battery-good.png";
    } else if (value >= 36 && value <= 75) {
      result = "나쁨";
      img_value = "img/battery-caution.png";
    } else if (value >= 76) {
      result = "매우나쁨";
      img_value = "img/battery-warning.png";
    }
  } else if (id == 7) //1.0
  {
    if (value >= 0 && value <= 30) {
      result = "좋음";
      img_value = "img/humidity-normal.png";
    } else if (value >= 31 && value <= 80) {
      result = "보통";
      img_value = "img/battery-good.png";
    } else if (value >= 81 && value <= 150) {
      result = "나쁨";
      img_value = "img/battery-caution.png";
    } else if (value >= 151) {
      result = "매우나쁨";
      img_value = "img/battery-warning.png";
    }
  }
  img_string = '<img class="img-responsive" src=' + img_value.toString() + ' width="25pt" height="20pt"/>';
  var data = "";
  data += '<div class="containerBox">' +
    img_string.toString() +
    '<div class="text-box">' +
    ' <h6 class="dataNumber">' + result.toString() + '</h6>' +
    '</div>' +
    '</div> &nbsp;' + value.toString();
  return data.toString();
}

function loadSensorTypes(callback) {
  if (_sensor_type_ !== null) {
    if (callback) callback(_sensor_type_);
    return
  }
  var filter = {
    fields: { guid: true, components: true, sensor_type: true, service_type: true }
  }
  loopback
    .find('/dictionaries', filter)
    .then((result) => {
      _dictionary_ = _.first(result);
      _sensor_type_ = _dictionary_.sensor_type;
      _service_type_ = _dictionary_.service_type;
      if (callback) callback(_sensor_type_);
    })
    .catch((err) => {
      console.log('error: ', err.toString())
    })
}

function getDictionary() {
  return _dictionary_;
}

function getSensorTypes() {
  return _sensor_type_;
}

function getServiceTypes() {
  return _service_type_ || {
    "USER": {
      "sis": "토양정보",
      "btfs": "비점오염",
      "srds": "위험물감시",
      "fumax": "염수분사",
      "swfm" : "산불감시"
    },
    "DEVICE": {
      "sis": "토양정보",
      "sis.leaf": "과수병",
      "protect": "수목보호",
      "btfs": "비점오염",
      "srds": "위험물감시",
      "fumax": "염수분사",
      "swfm" : "산불감시"
    }
  };
}

function loadThresholds(callback) {
  if (_thresholds_ !== null) {
    if (callback) callback(_thresholds_);
    return
  }
  loopback
    .find('/thresholds')
    .then((result) => {
      _thresholds_ = result
      if (callback) callback(_thresholds_);
    })
    .catch((err) => {
      console.log('error: ', err.toString())
    })
}

function getThresholds() {
  return _thresholds_;
}

loadSensorTypes()
loadThresholds()

export default {
  getStorage: getStorage,
  setStorage: setStorage,
  clearStorage: clearStorage,
  dateFormatter: dateFormatter,
  toFixed: toFixed,
  filteredItem: filteredItem,
  getWindDirectionName: getWindDirectionName,
  getPMlevelName: getPMlevelName,
  getSensorTypeName: getSensorTypeName,
  getFontawesomeIcon: getFontawesomeIcon,
  loadSensorTypes: loadSensorTypes,
  getDictionary: getDictionary,
  getSensorTypes: getSensorTypes,
  getServiceTypes: getServiceTypes,
  loadThresholds: loadThresholds,
  getThresholds: getThresholds,
  getThresholdByValue: getThresholdByValue
}