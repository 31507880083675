const IMAGES = {
    size: {
        width: 16,
        height: 16
      },
      HN: {
        type: 2,
        level: 0,
        status: '정상',
        name: 'img/humidity-normal.png'
      },
      HE: {
        type: 2,
        level: 1,
        status: '과다',
        name: 'img/humidity-excess.png'
      },
      HC: {
        type: 2,
        level: 2,
        status: '주의',
        name: 'img/humidity-caution.png'
      },
      HL: {
        type: 2,
        level: 3,
        status: '부족',
        name: 'img/humidity-lack.png'
      },
      HN3: {
        type: 12,
        level: 0,
        status: '정상',
        name: 'img/humidity-normal.png'
      },
      HE3: {
        type: 12,
        level: 1,
        status: '과다',
        name: 'img/humidity-excess.png'
      },
      HC3: {
        type: 12,
        level: 2,
        status: '주의',
        name: 'img/humidity-caution.png'
      },
      HL3: {
        type: 12,
        level: 3,
        status: '부족',
        name: 'img/humidity-lack.png'
      },
      BG: {
        type: 1,
        level: 0,
        status: '좋음',
        name: 'img/battery-good.png'
      },
      BN: {
        type: 1,
        level: 1,
        status: '보통',
        name: 'img/battery-normal.png'
      },
      BC: {
        type: 1,
        level: 2,
        status: '주의',
        name: 'img/battery-caution.png'
      },
      BW: {
        type: 1,
        level: 3,
        status: '경고',
        name: 'img/battery-warning.png'
      },
      PG: {
        type: 28,
        level: 0,
        status: '좋음',
        name: 'img/humidity-normal.png'
      },
      PN: {
        type: 28,
        level: 1,
        status: '보통',
        name: 'img/battery-good.png'
      },
      PC: {
        type: 28,
        level: 2,
        status: '나쁨',
        name: 'img/battery-caution.png'
      },
      PW: {
        type: 28,
        level: 3,
        status: '매우나쁨',
        name: 'img/battery-warning.png'
      },
      DG: {
        type: 8,
        level: 0,
        status: '보통',
        name: 'img/battery-good.png'
      },
      DN: {
        type: 8,
        level: 1,
        status: '정상',
        name: 'img/battery-good.png'
      },
      DC: {
        type: 8,
        level: 2,
        status: '주의',
        name: 'img/battery-caution.png'
      },
      DW: {
        type: 8,
        level: 3,
        status: '위험',
        name: 'img/battery-warning.png'
      }      
}

const SRDS_IMAGES = {
  size: {
      width: 16,
      height: 16
    },
    HN: {
      type: 2,
      level: 0,
      status: '정상',
      name: 'img/humidity-normal.png'
    },
    HE: {
      type: 2,
      level: 1,
      status: '과다',
      name: 'img/humidity-excess.png'
    },
    HC: {
      type: 2,
      level: 2,
      status: '주의',
      name: 'img/humidity-caution.png'
    },
    HL: {
      type: 2,
      level: 3,
      status: '부족',
      name: 'img/humidity-lack.png'
    },
    HN3: {
      type: 12,
      level: 0,
      status: '정상',
      name: 'img/humidity-normal.png'
    },
    HE3: {
      type: 12,
      level: 1,
      status: '과다',
      name: 'img/humidity-excess.png'
    },
    HC3: {
      type: 12,
      level: 2,
      status: '주의',
      name: 'img/humidity-caution.png'
    },
    HL3: {
      type: 12,
      level: 3,
      status: '부족',
      name: 'img/humidity-lack.png'
    },
    BG: {
      type: 1,
      level: 0,
      status: '좋음',
      name: 'img/battery-good.png'
    },
    BN: {
      type: 1,
      level: 1,
      status: '보통',
      name: 'img/battery-normal.png'
    },
    BC: {
      type: 1,
      level: 2,
      status: '주의',
      name: 'img/battery-caution.png'
    },
    BW: {
      type: 1,
      level: 3,
      status: '경고',
      name: 'img/battery-warning.png'
    },
    PG: {
      type: 28,
      level: 0,
      status: '좋음',
      name: 'img/humidity-normal.png'
    },
    PN: {
      type: 28,
      level: 1,
      status: '보통',
      name: 'img/battery-good.png'
    },
    PC: {
      type: 28,
      level: 2,
      status: '나쁨',
      name: 'img/battery-caution.png'
    },
    PW: {
      type: 28,
      level: 3,
      status: '매우나쁨',
      name: 'img/battery-warning.png'
    },
    DG: {
      type: 8,
      level: 0,
      status: '좋음',
      name: 'img/markers/device-normal.png'
    },
    DN: {
      type: 8,
      level: 1,
      status: '보통',
      name: 'img/markers/device-good.png'
    },
    DC: {
      type: 8,
      level: 2,
      status: '나쁨',
      name: 'img/markers/device-caution.png'
    },
    DW: {
      type: 8,
      level: 3,
      status: '매우나쁨',
      name: 'img/markers/device-warning.png'
    }      
}

const FUMAX_IMAGES = {
  size: {
      width: 16,
      height: 16
    },
    HN: {
      type: 2,
      level: 0,
      status: '정상',
      name: 'img/humidity-normal.png'
    },
    HE: {
      type: 2,
      level: 1,
      status: '과다',
      name: 'img/humidity-excess.png'
    },
    HC: {
      type: 2,
      level: 2,
      status: '주의',
      name: 'img/humidity-caution.png'
    },
    HL: {
      type: 2,
      level: 3,
      status: '부족',
      name: 'img/humidity-lack.png'
    },
    HN3: {
      type: 12,
      level: 0,
      status: '정상',
      name: 'img/humidity-normal.png'
    },
    HE3: {
      type: 12,
      level: 1,
      status: '과다',
      name: 'img/humidity-excess.png'
    },
    HC3: {
      type: 12,
      level: 2,
      status: '주의',
      name: 'img/humidity-caution.png'
    },
    HL3: {
      type: 12,
      level: 3,
      status: '부족',
      name: 'img/humidity-lack.png'
    },
    BG: {
      type: 1,
      level: 0,
      status: '좋음',
      name: 'img/battery-good.png'
    },
    BN: {
      type: 1,
      level: 1,
      status: '보통',
      name: 'img/battery-normal.png'
    },
    BC: {
      type: 1,
      level: 2,
      status: '주의',
      name: 'img/battery-caution.png'
    },
    BW: {
      type: 1,
      level: 3,
      status: '경고',
      name: 'img/battery-warning.png'
    },
    PG: {
      type: 28,
      level: 0,
      status: '좋음',
      name: 'img/humidity-normal.png'
    },
    PN: {
      type: 28,
      level: 1,
      status: '보통',
      name: 'img/battery-good.png'
    },
    PC: {
      type: 28,
      level: 2,
      status: '나쁨',
      name: 'img/battery-caution.png'
    },
    PW: {
      type: 28,
      level: 3,
      status: '매우나쁨',
      name: 'img/battery-warning.png'
    },
    DG: {
      type: 8,
      level: 0,
      status: '좋음',
      name: 'img/markers/device-normal.png'
    },
    DN: {
      type: 8,
      level: 1,
      status: '보통',
      name: 'img/markers/device-good.png'
    },
    DC: {
      type: 8,
      level: 2,
      status: '나쁨',
      name: 'img/markers/device-caution.png'
    },
    DW: {
      type: 8,
      level: 3,
      status: '매우나쁨',
      name: 'img/markers/device-warning.png'
    }, 
    /* 통신상태 */
    CN:{
      status: '전체',
      name: 'img/markers/device-normal.png'
    },
    CG:{
      status: '정상',
      name: 'img/markers/device-good.png'
    },
    CW:{
      status: '불량',
      name: 'img/markers/device-warning.png'
    },
    /* 작동상태 */
    AW:{},
    AR:{},
    AW:{},      
}

const MARKER_IMAGES = {
  size: {
    width: 40,
    height: 42
  },
  HN: {
    type: 2,
    level: 0,
    name: 'img/markers/humidity-normal.png'
  },
  HE: {
    type: 2,
    level: 1,
    name: 'img/markers/humidity-excess.png'
  },
  HC: {
    type: 2,
    level: 2,
    name: 'img/markers/humidity-caution.png'
  },
  HL: {
    type: 2,
    level: 3,
    name: 'img/markers/humidity-lack.png'
  },
  BG: {
    type: 1,
    level: 0,
    name: 'img/markers/battery-good.png'
  },
  BN: {
    type: 1,
    level: 1,
    name: 'img/markers/battery-normal.png'
  },
  BC: {
    type: 1,
    level: 2,
    name: 'img/markers/battery-caution.png'
  },
  BW: {
    type: 1,
    level: 3,
    name: 'img/markers/battery-warning.png'
  },
  DG: {
    type: 8,
    level: 0,
    name: 'img/markers/srds-normal.png'
  },
  DN: {
    type: 8,
    level: 1,
    name: 'img/markers/srds-good.png'
  },
  DC: {
    type: 8,
    level: 2,
    name: 'img/markers/srds-caution.png'
  },
  DW: {
    type: 8,
    level: 3,
    name: 'img/markers/srds-warning.png'
  }
}

const SENSOR_TYPE = [
  {
    'code': 1,
    'id': 1,
    'value': '배터리',
    'unit': '(V)',
    'used': true
  },
  {
    'code': 2,
    'id': 1,
    'value': '토양1종 - 수분',
    'unit': '(%)',
    'used': true
  },
  {
    'code': 3,
    'id': 1,
    'value': '경도',
    'unit': '',
    'used': false
  },
  {
    'code': 3,
    'id': 2,
    'value': '위도',
    'unit': '',
    'used': false
  },
  {
    'code': 4,
    'id': 1,
    'value': '케이스-온도',
    'unit': '(℃)',
    'used': false
  },
  {
    'code': 5,
    'id': 1,
    'value': '케이스-습도',
    'unit': '(%)',
    'used': false
  },
  {
    'code': 6,
    'id': 1,
    'value': '온도',
    'unit': '(℃)',
    'used': false
  },
  {
    'code': 6,
    'id': 2,
    'value': '습도',
    'unit': '(%)',
    'used': false
  },
  {
    'code': 8,
    'id': 1,
    'value': 'Roll',
    'unit': '(º)',
    'used': true
  },
  {
    'code': 8,
    'id': 2,
    'value': 'Pitch',
    'unit': '(º)',
    'used': true
  },
  {
    'code': 8,
    'id': 3,
    'value': 'Yaw',
    'unit': '(º)',
    'used': true
  },
  {
    'code': 7,
    'id': 1,
    'value': 'Roll(원점)',
    'unit': '(º)',
    'used': true
  },
  {
    'code': 7,
    'id': 2,
    'value': 'Pitch(원점)',
    'unit': '(º)',
    'used': true
  },
  {
    'code': 7,
    'id': 3,
    'value': 'Yaw(원점)',
    'unit': '(º)',
    'used': true
  },
  {
    'code': 10,
    'id': 1,
    'value': 'RSRP',
    'unit': '(dBm)',
    'used': true
  },
  {
    'code': 10,
    'id': 2,
    'value': 'SNR',
    'unit': '(dB)',
    'used': true
  },
  {
    'code': 11,
    'id': 1,
    'value': '토양-PH',
    'unit': '(ph)',
    'used': true
  },
  {
    'code': 12,
    'id': 1,
    'value': '토양3종 - 수분',
    'unit': '(%)',
    'used': true
  },
  {
    'code': 12,
    'id': 2,
    'value': '토양3종 - 온도',
    'unit': '(℃)',
    'used': true
  },
  {
    'code': 12,
    'id': 3,
    'value': '토양3종 - EC',
    'unit': '(mS/cm)',
    'used': true
  },
  {
    'code': 13,
    'id': 1,
    'value': '가스-메탄(CH4)',
    'unit': '(ppm)',
    'used': true
  },
  {
    'code': 14,
    'id': 1,
    'value': '가스-황화수소(H2S)',
    'unit': '(ppm)',
    'used': true
  },
  {
    'code': 15,
    'id': 1,
    'value': '가스-산소(O2)',
    'unit': '(%)',
    'used': true
  },
  {
    'code': 16,
    'id': 1,
    'value': '가스-이산화탄소(CO2)',
    'unit': '(ppm)',
    'used': true
  },
  {
    'code': 17,
    'id': 1,
    'value': '초음파거리',
    'unit': '(m)',
    'used': true
  },
  {
    'code': 19,
    'id': 1,
    'value': '수위(수압)',
    'unit': '(m)',
    'used': true
  },
  {
    'code': 20,
    'id': 1,
    'value': '대기온도',
    'unit': '(℃)',
    'used': false
  },
  {
    'code': 21,
    'id': 1,
    'value': '대기습도',
    'unit': '(%)',
    'used': false
  },
  {
    'code': 22,
    'id': 1,
    'value': '강수량',
    'unit': '(mm)',
    'used': false
  },
  {
    'code': 23,
    'id': 1,
    'value': '풍속',
    'unit': '(m/s)',
    'used': false
  },
  {
    'code': 24,
    'id': 1,
    'value': '유량(유입)',
    'unit': '(m3/hr)',
    'used': false
  },
  {
    'code': 24,
    'id': 2,
    'value': '유량(유출)',
    'unit': '(m3/hr)',
    'used': false
  },
  {
    'code': 25,
    'id': 1,
    'value': '부유물질(유입)',
    'unit': '(mg/l)',
    'used': false
  },
  {
    'code': 25,
    'id': 2,
    'value': '부유물질(유출)',
    'unit': '(mg/l)',
    'used': false
  },
  {
    'code': 66,
    'id': 1,
    'value': '토양1종#2 - 수분',
    'unit': '(%)',
    'used': true
  },
  {
    'code': 76,
    'id': 1,
    'value': '토양3종#2 - 수분',
    'unit': '(%)',
    'used': true
  },
  {
    'code': 76,
    'id': 2,
    'value': '토양3종#2 - 온도',
    'unit': '(℃)',
    'used': true
  },
  {
    'code': 76,
    'id': 3,
    'value': '토양3종#2 - EC',
    'unit': '(mS/cm)',
    'used': true
  },
  {
    'code': 130,
    'id': 1,
    'value': '토양1종#3 - 수분',
    'unit': '(%)',
    'used': true
  },
  {
    'code': 140,
    'id': 1,
    'value': '토양3종#3 - 수분',
    'unit': '(%)',
    'used': true
  },
  {
    'code': 140,
    'id': 2,
    'value': '토양3종#3 - 온도',
    'unit': '(℃)',
    'used': true
  },
  {
    'code': 140,
    'id': 3,
    'value': '토양3종#3 - EC',
    'unit': '(mS/cm)',
    'used': true
  },
  {
    'code': 194,
    'id': 1,
    'value': '토양1종#4 - 수분',
    'unit': '(%)',
    'used': true
  },
  {
    'code': 204,
    'id': 1,
    'value': '토양3종#4 - 수분',
    'unit': '(%)',
    'used': true
  },
  {
    'code': 204,
    'id': 2,
    'value': '토양3종#4 - 온도',
    'unit': '(℃)',
    'used': true
  },
  {
    'code': 204,
    'id': 3,
    'value': '토양3종#4 - EC',
    'unit': '(mS/cm)',
    'used': true
  },
  {
    'code': 1000,
    'id': 1,
    'value': '수액속도-Out',
    'unit': '(cm/hr)',
    'used': true
  },
  {
    'code': 1000,
    'id': 2,
    'value': '수액속도-In',
    'unit': '(cm/hr)',
    'used': true
  },
  {
    'code': 1000,
    'id': 3,
    'value': '수액양-Out',
    'unit': '(kg/hr)',
    'used': true
  },
  {
    'code': 1000,
    'id': 4,
    'value': '수액양-In',
    'unit': '(kg/hr)',
    'used': true
  },
  {
    'code': 1001,
    'id': 1,
    'value': '수액속도-Out',
    'unit': '(cm/hr)',
    'used': true
  },
  {
    'code': 1001,
    'id': 2,
    'value': '수액속도-In',
    'unit': '(cm/hr)',
    'used': true
  },
  {
    'code': 1001,
    'id': 3,
    'value': '수액양-Out',
    'unit': '(kg/hr)',
    'used': true
  },
  {
    'code': 1001,
    'id': 4,
    'value': '수액양-In',
    'unit': '(kg/hr)',
    'used': true
  },
  {
    'code': 2000,
    'id': 1,
    'value': '열화상이미지',
    'unit': '',
    'used': true
  },
  {
    'code': 3000,
    'id': 1,
    'value': '관수',
    'unit': '(회)',
    'used': true
  }    
]

const DATE = {
  TYPE: {
    RAWDATA: 1,
    DAILY: 2,
    HOURLY: 3
  },
  FORMAT: {
    RAWDATA: 'dd일HH시',
    DAILY: 'MM월dd일',
    HOURLY: 'dd일HH시'
  },
  NAME: {
    RAWDATA: '측정값',
    HOURLY: '시간별',
    DAILY: '일자별'
  }
}

var SERVICE_TYPE = {
  USER: {
    sis: '토양정보',
    btfs: '비점오염',
    thcs: '흡탈착공정',
    srds: '위험물감시',
    fumax: '염수분사',
    snow: '제설함',
    door: '출입문감시',
    'watek': '워텍',
    swfm: '산불감시'
  },
  DEVICE: {
    sis: '토양정보',
    'sis.leaf': '과수병',
    'sis.valve': '자동관수',
    protect: '수목보호',
    btfs: '비점오염',
    thcs: '흡탈착공정',
    srds: '위험물감시',
    fumax: '염수분사',
    'fumax.snow': '제설함',
    door: '출입문감시',
    'watek': '워텍',
    swfm: '산불감시'
  }
}

export {
  IMAGES,
  SRDS_IMAGES,
  FUMAX_IMAGES, 
  MARKER_IMAGES,
  SENSOR_TYPE,
  SERVICE_TYPE,
  DATE
}
