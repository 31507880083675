<template>
  <div>
    <router-view></router-view>
    <Notify ref="notifyDialog"/>
  </div>
</template>

<script>
import loopback from '@/services/loopback';
import EventBus from '@/services/EventBus'
import utils from '@/services/utils';
import { LEAF_TYPES } from "@/constants/deviceSettings";

export default {
  name: 'App',
  created() {
    // Boot Application
    this.$store.dispatch('async/syncLoopback');
    this.$store.dispatch('auth/syncAuth')
  },
  mounted() {
    this.initialize()
    EventBus.$on('subscribe', this.initialize)
  },
  methods: {
    receiveMessage(frame) {
      var self = this;
      var message = JSON.parse(frame.body)
      console.log('--------------------------------------------------')
      console.log('push message : ', message);
      console.log('--------------------------------------------------')
      if (message.type === 'event') {
        message.datas.forEach(function(elem) {
          if (elem.subtype === 'picture' && !_.isUndefined(elem.predict)) {
            var predict = elem.info.predict
            var message = '신규 사진 : ' + LEAF_TYPES[predict.label] + '(확률 : ' + predict.probability.toFixed(5) + ')'
            self.$refs.notifyDialog.show(message)
          }
        })
      } else
      if (message.type === 'water_alarm' || message.type === 'nutrient_alarm') {
        var title_msg = "";
        if (message.type === 'water_alarm')
          title_msg = "저수조 알림";
        else if (message.type === 'nutrient_alarm')
          title_msg = "양액부족 알림"
        var message = '{0} : {1}'.format(title_msg, message.description)
        this.$refs.notifyDialog.show(message)
      } else
      if (message.type === 'alarm') {
        EventBus.$emit('alarmData', {
          message: message
        });
      }
      EventBus.$emit('reload', {});
    },
    initialize() {
      if (!this.$store.state.auth.user_info) 
        return;
      utils.loadSensorTypes();
      var user = this.$store.state.auth.user_info.user;
      var stomp = this.$store.state.auth.user_info.stomp;
      loopback.Subscribe(user.guid, this.receiveMessage, stomp.login, stomp.login, stomp.password)
    }

  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
