import loopback from '@/services/loopback';
import router from '@/router';
import { Base64 } from 'js-base64';

function setStorage(key, data) {
  window.localStorage.setItem(key, Base64.encode(JSON.stringify(data)));
}

function getStorage(key) {
  try {
    var v = window.localStorage.getItem(key);
    return JSON.parse(Base64.decode(v));
  } catch (error) {
    return;
  }
}

function clearStorage(key) {
  window.localStorage.clear();
}

/**
 * Sync loopback token with current state
*/
export function syncToken({state, commit, dispatch}) {

  if (loopback.token) {
    commit('setAccessToken', loopback.token);
    if (state.user_info === null) {
      return dispatch(
        'loadAccount',
        loopback.token.userId,
      ).catch((err) => {
        commit('setAccessToken', null);
        return err;
      });
    }
  }
  return Promise.resolve();
}

function evaluateRoute(state, to, from, next) {
  return (sessionError) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page (except when it's profile route and
      // there is an access_token).
      if (to.name === 'profile' && to.query.access_token) {
        next();
      } else if (!state.access_token) {
        next({
          name: 'Login',
          params: {
            sessionError,
          },
        });
      } else {
        next();
      }
    } else {
      next(); // make sure to always call next()!
    }
  };
}

/**
 * Sync router for auth
 */
export function syncRouter({state, dispatch}, {router}) {
  router.beforeEach((to, from, next) => {
    dispatch('syncToken').then(
      evaluateRoute(state, to, from, next)
    );
  });
}

/**
 * Sign-in account with email and password (stores in state.account)
 * @param  {Function} commit   commit mutations function
 * @param  {String}   email    user email
 * @param  {String}   password user password
 * @return {Promise}           promise of logged user
 */
export function signIn({commit, dispatch, state}, {email, password}) {
  return loopback
    .post('/user/login', {
      email,
      password,
    })
    .then((token) => {
      commit('setAccessToken', token);
      // Update Loopback Token
      if (state.access_token !== null) {
        loopback.setToken(state.access_token);
      } else {
        loopback.removeToken();
      }

      return dispatch('loadAccount', state.access_token.userId)
        .then(acc => {
          return dispatch('loadUserInfo', acc)
        })
        .then(userInfo => {
          return Promise.resolve(token)
        })

    });
}

/**
 * Sign-out current logged-in account
 *  (assumes that current state.account is not null)
 * @param  {Function} commit commit mutations function
 * @return {Promise}         promise of the logout
 */
export function signOut({commit, state}) {
  var clearUserInfo = function() {
    commit('setAccessToken', null);
    commit('setUserInfo', null);
    commit('setAccount', null);
    loopback.removeToken();
    clearStorage('account')
    clearStorage('userInfo')
    router.push({name: 'Login'});
  }
  return loopback
    .post('/user/logout', {
      // eslint-disable-next-line dot-notation
      accessToken: state['access_token'],
    })
    .then(() => {
      clearUserInfo();
    })
    .catch(err => {
      console.log('signOut error:', err.toString())
      clearUserInfo();
    })
}



/**
 * Load an account by userId in state.account
 * @param  {Function} commit    commit mutations function
 * @param  {Number}   userId account id
 * @return {Promise}            promise of the account
 */
export function loadAccount({commit, dispatch}, userId) {
  return loopback
    .get(`/user/${userId}`)
    .then(acc => {
      commit('setAccount', acc)
      setStorage('account', acc)
      return Promise.resolve(acc)
    })
    .catch((err) => {
      loopback.removeToken();
      return Promise.reject(err);
    });
}

function getStomp() {
  return loopback
    .method('user', 'getStomp')
    .then(res => {
      return Promise.resolve(res)
    })
    .catch(err => {
      return Promise.resolve({
        login:'tech9',
        password:'1111'
      })
    })
}
export function loadUserInfo({commit, dispatch}, account) {
  let group_guid = account.group_guid
  if (_.isUndefined(group_guid) || _.isEmpty(account.group_guid)) {
    return Promise.reject(new Error('Unknown(empty) group_guid'))
  }

  let sites = undefined;
  let stomp = undefined;
  return getStomp()
    .then(res => {
      stomp = res
      return loopback
        .method('/user', 'getSites')
    })
    .then((res) => {
      sites = res
      return loopback.find('/groups')
    })
    .then(groups => {
      let userInfo = {
        group_list: groups,
        group: _.find(groups, {guid:group_guid}),
        sites: sites,
        dashboard: [],
        user: account,
        stomp: stomp
      }
      // set local site
      userInfo.user.active_site_guids = [];
      userInfo.user.active_site_index = 0;
      //
      commit('setUserInfo', userInfo)
      setStorage('userInfo', userInfo)
      commit('setIsAdmin', userInfo.group.group_type === 'admin_type')
      return Promise.resolve(userInfo)
    })
    .catch((err) => {
      loopback.removeToken();
      return Promise.reject(err);
    });

  // return loopback
  //   .find('/groups')
  //   .then(groups => {
  //     let userInfo = {
  //       group_list: groups,
  //       group: _.find(groups, {guid:group_guid}),
  //       sites: account.site_guids,
  //       dashboard: [],
  //       user: account
  //     }      
  //     commit('setUserInfo', userInfo)
  //     setStorage('userInfo', userInfo)
  //     commit('setIsAdmin', userInfo.group.group_type === 'admin_type')
  //     return Promise.resolve(userInfo)
  //   })
  //   .catch((err) => {
  //     loopback.removeToken();
  //     return Promise.reject(err);
  //   });

  // return loopback
  //   .findById(`/groups`, group_guid)
  //   .then(group => {
  //     let userInfo = {
  //       group_list: [group_guid],
  //       group: group,
  //       sites: account.site_guids,
  //       dashboard: [],
  //       user: account
  //     }      
  //     commit('setUserInfo', userInfo)
  //     setStorage('userInfo', userInfo)
  //     commit('setIsAdmin', group.group_type === 'admin_type')
  //     return Promise.resolve(userInfo)
  //   })
  //   .catch((err) => {
  //     loopback.removeToken();
  //     return Promise.reject(err);
  //   });
}

export function syncAuth({state, commit}, cb) {
  let info = getStorage('userInfo')
  if (!_.isEmpty(info)) {
    commit('setAccount', info.account)
    commit('setUserInfo', info)
    commit('setIsAdmin', info.group.group_type === 'admin_type')
    loopback
      .get('/user/' + info.user.guid)
      .then(user => {
        loopback
        .method('/user', 'getSites')
        .then(sites => {
          info.sites = sites;
          info.user = user;
          info.user.active_site_guids = [];
          info.user.active_site_index = 0;
          commit('setUserInfo', info)
          setStorage('userInfo', info)
          if (cb) {
            cb(info);
          }
        })
      })
  }
}

/**
 * Reset the password of the current logged-in account
 *  (assumes that current state.account is not null)
 *  (assumes that current state.access_token is not null)
 * @param  {Function} commit       commit mutations function
 * @param  {Object}   state        Vuex state
 * @param  {String}   oldPassword  old password
 * @param  {String}   newPassword  new password
 * @return {Promise}              promise of the password reset
 */
export function resetPassword(ctx, {oldPassword, newPassword}) {
  return loopback
    .post(
      '/user/change-password',
      {oldPassword, newPassword}
    );
}

/**
 * Send a email to the account user to reset the password
 * @param  {Function} commit       commit mutations function
 * @param  {String}   email        user email
 * @return {Promise}               promise of the sent email
 */
export function rememberPassword(ctx, email) {
  return loopback
    .post('/user/reset', {email});
}